import {
  DOMAIN_HOSTNAME,
  DEV_DOMAIN_SUB,
  BREAKPOINT_XLARGE,
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_SMALL,
  MAX_LARGE_REPORTER_NUM,
  MAX_MEDIUM_REPORTER_NUM,
  MAX_LARGE_JPOD_REPORTER_NUM,
  MAX_MEDIUM_JPOD_REPORTER_NUM,
  MAX_LARGE_IMMERSION_TYPE_ARTICLE_REPORTER_NUM,
  MAX_SUBSCRIBE_LIST,
  CHANNEL_TYPE_A,
  CHANNEL_TYPE_I,
  BBS_PATH,
  API_PATH,
  WEB_PATH,
  IMG_PATH,
  PDS_PATH,
  SEARCH_PATH,
  STATIC_PATH,
  ABTEST_PATH,
  JTBC_NEWS_PATH,
  JTBC_COUNTER_PATH,
  CRUZ_PATH,
  PEOPLE_SEARCH_PATH,
  CRUZ_TOP_PATH,
  OYA_PATH,
  BLANK_IMG,
  REP_DEFAULT_IMG,
  DEFAULT_IMG_1_1,
  DEFAULT_IMG_16_9,
  ACCOUNT_URL,
  ACCOUNT_API_PATH,
  PAYMENT_API_PATH,
  LOGIN_URL,
  JOIN_URL,
  LOGOUT_URL,
  MY_INFO_URL,
  MY_PAGE_URL,
  COOKIE_NAME,
  LOCAL_NAME,
  SESSION_NAME,
  LARGE_TEXTNUM,
  MEDIUM_TEXTNUM,
  SMALL_TEXTNUM,
  LARGE_TEXT,
  MEDIUM_TEXT,
  SMALL_TEXT,
  DEFAULT_ARTICLE_FONTSIZE,
  MAX_SAVE_SEARCH_WORD,
  EMAIL_ADDR,
  CALENDER_IMG,
  CHOICE_BOOKMARK,
  LAZY_LOAD_DEFAULT_OPTION,
  FB_APP_ID,
  FB_APP_VERSION,
  APP_MENU_HEIGHT,
  IS_REALESTATE,
  STICKY_DELAY,
  APIGEE_API_URL,
  APIGEE_X_API_KEY,
} from '@/_modules/resource/resource.constant';

/**
 * 개발서버 체크
 */
window.DOMAIN_HOSTNAME = DOMAIN_HOSTNAME;
window.DEV_DOMAIN_SUB = DEV_DOMAIN_SUB;

/**
 * BREAKPOINTS
 */
window.BREAKPOINT_XLARGE = BREAKPOINT_XLARGE;
window.BREAKPOINT_LARGE = BREAKPOINT_LARGE;
window.BREAKPOINT_MEDIUM = BREAKPOINT_MEDIUM;
window.BREAKPOINT_SMALL = BREAKPOINT_SMALL;

/**
 * 기사페이지에서 노출되는 기자 최대 수
 */
window.MAX_LARGE_REPORTER_NUM = MAX_LARGE_REPORTER_NUM;
window.MAX_MEDIUM_REPORTER_NUM = MAX_MEDIUM_REPORTER_NUM;
window.MAX_LARGE_JPOD_REPORTER_NUM = MAX_LARGE_JPOD_REPORTER_NUM;
window.MAX_MEDIUM_JPOD_REPORTER_NUM = MAX_MEDIUM_JPOD_REPORTER_NUM;
window.MAX_LARGE_IMMERSION_TYPE_ARTICLE_REPORTER_NUM = MAX_LARGE_IMMERSION_TYPE_ARTICLE_REPORTER_NUM;

/**
 * 최대 수
 */
window.MAX_SUBSCRIBE_LIST = MAX_SUBSCRIBE_LIST;

/**
 * CHANNEL_TYPE
 */
window.CHANNEL_TYPE_A = CHANNEL_TYPE_A; // 기사
window.CHANNEL_TYPE_I = CHANNEL_TYPE_I; // 패키지

/**
 * 내부 PATH
 */
window.BBS_PATH = BBS_PATH;
window.API_PATH = API_PATH;
window.WEB_PATH = WEB_PATH;
window.IMG_PATH = IMG_PATH;
window.PDS_PATH = PDS_PATH;
window.SEARCH_PATH = SEARCH_PATH;
window.STATIC_PATH = STATIC_PATH;
window.ABTEST_PATH = ABTEST_PATH;

/**
 * 외부 PATH
 */
window.JTBC_NEWS_PATH = JTBC_NEWS_PATH;
window.JTBC_COUNTER_PATH = JTBC_COUNTER_PATH;
window.CRUZ_PATH = CRUZ_PATH;
window.PEOPLE_SEARCH_PATH = PEOPLE_SEARCH_PATH;
window.CRUZ_TOP_PATH = CRUZ_TOP_PATH;
window.OYA_PATH = OYA_PATH;

/**
 * 기본이미지
 */
window.BLANK_IMG = BLANK_IMG;
window.REP_DEFAULT_IMG = REP_DEFAULT_IMG;
window.DEFAULT_IMG_1_1 = DEFAULT_IMG_1_1;
window.DEFAULT_IMG_16_9 = DEFAULT_IMG_16_9;

window.ACCOUNT_URL = ACCOUNT_URL;
window.ACCOUNT_API_PATH = ACCOUNT_API_PATH;
window.PAYMENT_API_PATH = PAYMENT_API_PATH;
window.LOGIN_URL = LOGIN_URL;
window.JOIN_URL = JOIN_URL;
window.LOGOUT_URL = LOGOUT_URL;
window.MY_INFO_URL = MY_INFO_URL;
window.MY_PAGE_URL = MY_PAGE_URL;

window.APIGEE_API_URL = APIGEE_API_URL;
window.APIGEE_X_API_KEY = APIGEE_X_API_KEY;

/**
 * COOKIE NAME
 */
window.COOKIE_NAME = COOKIE_NAME;

/**
 * LOCAL STORAGE NAME
 */
window.LOCAL_NAME = LOCAL_NAME;

/**
 * SESSION STORAGE NAME
 */
window.SESSION_NAME = SESSION_NAME;

/**
 * 2줄로 잘리는 글자 수
 */
window.LARGE_TEXTNUM = LARGE_TEXTNUM;
window.MEDIUM_TEXTNUM = MEDIUM_TEXTNUM;
window.SMALL_TEXTNUM = SMALL_TEXTNUM;

/**
 * 3줄로 잘리는 글자 수
 */
window.LARGE_TEXT = LARGE_TEXT;
window.MEDIUM_TEXT = MEDIUM_TEXT;
window.SMALL_TEXT = SMALL_TEXT;

/**
 * 기사 본문 기본 폰트 크기
 */
window.DEFAULT_ARTICLE_FONTSIZE = DEFAULT_ARTICLE_FONTSIZE;

/**
 * 검색 히스토리 최근 검색 최대 개수
 */
window.MAX_SAVE_SEARCH_WORD = MAX_SAVE_SEARCH_WORD;

/**
 * 이메일 주소 배열선언
 */
window.EMAIL_ADDR = EMAIL_ADDR;

window.CALENDER_IMG = CALENDER_IMG;

/**
 * 비로그인 사용자가 북마크 하기 위해 선택한 컨텐츠ID의 sessionStorage item key
 */
window.CHOICE_BOOKMARK = CHOICE_BOOKMARK;

window.LAZY_LOAD_DEFAULT_OPTION = LAZY_LOAD_DEFAULT_OPTION;

/**
 * 페이스북 키
 */
window.FB_APP_ID = FB_APP_ID;
window.FB_APP_VERSION = FB_APP_VERSION;

/**
 * 앱 메뉴바 높이
 */
window.APP_MENU_HEIGHT = APP_MENU_HEIGHT;

/**
 * 부동산 사이트 체크
 */
window.IS_REALESTATE = IS_REALESTATE;

/**
 * 스티키 delay (민감하지 않게 처리)
 */
window.STICKY_DELAY = STICKY_DELAY;
