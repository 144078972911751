/**
 * 개발서버 체크
 */
export const DOMAIN_HOSTNAME = window.location.hostname;
// var DEV_DOMAIN_SUB = ((DOMAIN_HOSTNAME.indexOf('dev-') >= 0 || DOMAIN_HOSTNAME.indexOf('loc-') >= 0) ? 'dev-' : (DOMAIN_HOSTNAME.indexOf('stg-') >= 0 ? 'stg-' : ''));
export const DEV_DOMAIN_SUB =
  // eslint-disable-next-line eqeqeq
  DOMAIN_HOSTNAME.search(/loc-|local-|dev-/i) == 0 ? 'dev-' : DOMAIN_HOSTNAME.search(/stg-/i) == 0 ? 'stg-' : '';
/*
DOMAIN_HOSTNAME.search(/loc-|local-/i) === 0
    ? 'local-'
    : DOMAIN_HOSTNAME.search(/dev-/i) === 0
    ? 'dev-'
    : DOMAIN_HOSTNAME.search(/stg-/i) === 0
    ? 'stg-'
    : ''; // jaeil test
*/

/**
 * BREAKPOINTS
 */
export const BREAKPOINT_XLARGE = 1400;
export const BREAKPOINT_LARGE = 986;
export const BREAKPOINT_MEDIUM = 690;
export const BREAKPOINT_SMALL = 375;

/**
 * 기사페이지에서 노출되는 기자 최대 수
 */
export const MAX_LARGE_REPORTER_NUM = 5;
export const MAX_MEDIUM_REPORTER_NUM = 3;
export const MAX_LARGE_JPOD_REPORTER_NUM = 4;
export const MAX_MEDIUM_JPOD_REPORTER_NUM = 2;
export const MAX_LARGE_IMMERSION_TYPE_ARTICLE_REPORTER_NUM = 4;

/**
 * 최대 수
 */
export const MAX_SUBSCRIBE_LIST = 31;

/**
 * CHANNEL_TYPE
 */
export const CHANNEL_TYPE_A = 'A'; // 기사
export const CHANNEL_TYPE_I = 'I'; // 패키지

/**
 * 내부 PATH
 */
export const BBS_PATH = 'https://' + DEV_DOMAIN_SUB + 'bbs.joongang.co.kr';
export const API_PATH = 'https://' + DEV_DOMAIN_SUB + 'api.joongang.co.kr/moka_api/';
export const WEB_PATH = 'https://' + DEV_DOMAIN_SUB + 'www.joongang.co.kr';
export const IMG_PATH = 'https://' + DEV_DOMAIN_SUB + 'img.joongang.co.kr';
export const PDS_PATH = 'https://' + DEV_DOMAIN_SUB + 'pds.joongang.co.kr';
export const SEARCH_PATH = 'https://' + DEV_DOMAIN_SUB + 'www.joongang.co.kr/search?keyword=';
export const STATIC_PATH = 'https://' + DEV_DOMAIN_SUB + 'static.joongang.co.kr';
export const ABTEST_PATH = 'https://' + DEV_DOMAIN_SUB + 'abtest.joongang.co.kr';

/**
 * 외부 PATH
 */
export const JTBC_NEWS_PATH = 'https://news.jtbc.joins.com';
export const JTBC_COUNTER_PATH = 'https://counter.jtbc.joins.com';
export const CRUZ_PATH = 'https://cruz.joongang.co.kr/';
export const PEOPLE_SEARCH_PATH = 'http://people.joins.com/Search/?pgi=1&ps=&q=';
export const CRUZ_TOP_PATH = 'https://cruz.joongang.co.kr/top';
export const OYA_PATH = 'https://oya.joongang.co.kr';

/**
 * 기본이미지
 */
export const BLANK_IMG = 'https://' + DEV_DOMAIN_SUB + 'img.joongang.co.kr/pubimg/blankImg.jpg';
export const REP_DEFAULT_IMG =
  'https://' + DEV_DOMAIN_SUB + 'img.joongang.co.kr/pubimg/reporter/img-profile-none@2x.png';
export const DEFAULT_IMG_1_1 =
  'https://' + DEV_DOMAIN_SUB + 'img.joongang.co.kr/pubimg/default/default_thumb_img_1_1@3x.png';
export const DEFAULT_IMG_16_9 =
  'https://' + DEV_DOMAIN_SUB + 'img.joongang.co.kr/pubimg/default/default_thumb_img_16_9@3x.png';

/**
 * account url
 */
let accountUrl = 'https://account.joongang.co.kr/';
let accountApiPath = 'https://account-api.joongang.co.kr/';
let paymentApiPath = 'https://pay-api.joongang.co.kr/';
let purchaseUrl = WEB_PATH + '/purchase/main';
let apigeeApiUrl = 'https://gapi.joongang.co.kr';
let apigeeXApiKey = 'kcf21LrvpAmfostT2ynsRUfG9rFegjzYhlkhZiobJnSL0ubl';

if (DEV_DOMAIN_SUB === 'dev-') {
  accountUrl = 'https://dev-account.joongang.co.kr/';
  accountApiPath = 'https://dev-account-api.joongang.co.kr/';
  paymentApiPath = 'https://dev-pay-api.joongang.co.kr/';
  purchaseUrl = 'https://dev-www.joongang.co.kr/purchase/main';
  apigeeApiUrl = 'https://stg-gapi.joongang.co.kr';
  apigeeXApiKey = 'pPFWGQAFTJoclGwkp46YQ0Nzwk23TkzaajdYW8kexIhFCStd';
} else if (DEV_DOMAIN_SUB === 'stg-') {
  accountUrl = 'https://dev-account.joongang.co.kr/';
  accountApiPath = 'https://dev-account-api.joongang.co.kr/';
  paymentApiPath = 'https://dev-pay-api.joongang.co.kr/';
  purchaseUrl = 'https://dev-www.joongang.co.kr/purchase/main';
  apigeeApiUrl = 'https://stg-gapi.joongang.co.kr';
  apigeeXApiKey = 'pPFWGQAFTJoclGwkp46YQ0Nzwk23TkzaajdYW8kexIhFCStd';
}

export const APIGEE_API_URL = apigeeApiUrl;
export const APIGEE_X_API_KEY = apigeeXApiKey;

export const ACCOUNT_URL = accountUrl;
export const ACCOUNT_API_PATH = accountApiPath;
export const PAYMENT_API_PATH = paymentApiPath;
export const PLUS_PURCHASE_URL = purchaseUrl;
export const LOGIN_URL = ACCOUNT_URL + 'login?targetURL=' + encodeURIComponent(window.location.href);
export const JOIN_URL = ACCOUNT_URL + 'join?targetURL=' + encodeURIComponent(window.location.href);
export const MY_INFO_URL = ACCOUNT_URL + 'user/myinfo?targetURL=' + encodeURIComponent(window.location.href);

export const MY_PAGE_URL = WEB_PATH + '/mynews'; // ?targetURL=' + encodeURIComponent(window.location.href);

const isMypage = /^\/(mynews|myfeed)/.test(location.pathname);
const targetUrl = isMypage ? WEB_PATH : window.location.href;
export const LOGOUT_URL = ACCOUNT_URL + 'logout?targetURL=' + encodeURIComponent(targetUrl);

/**
 * COOKIE NAME
 */
export const COOKIE_NAME = {
  GA: '_ga',
  PCID: 'PCID',
  MOKA_MEM: 'MokaMem',
  JA_MEM_SSO_INFO: 'JAMemSSOInfo',
  FONTSIZE: 'ArticleFontSize',
  HIGHLIGHT_COLOR: 'HighlightColor',
  IS_SAVING_KEYWORD: 'isSavingKeyword',
  JA_MEM_SSO_REFRESH: 'JAMemSSORefresh',
  JA_MEM_SSO_REFRESH_DATE: 'JAMemSSORefreshDate',
  JA_MEM_PAID: 'JAMemPaid',
  JA_MEM_PAID_REFRESH_DATE: 'JAMemPaidRefreshDate',
  JA_MEM_PAID_REFRESH_COOKIE: 'paidRef',
  JA_MEM_SSO_COMN: 'JAMemSSOComn',
  LOGIN_PROPOSAL: 'loginProposal',
  SAVED_SOKBO_ID: 'SavedSokboId',
  IS_PUSH: 'isPush',
  IOS_FIRST_PUSH_SETTING: 'isIosFirstPushSetting',
  DEVICE_TYPE: 'deviceType',
  PUSH_TOKEN: 'push_token',
  APP_MENU_HEIGHT: 'appMenuHeight',
  IS_LOGIN_HISTORY: 'isLoginHistory',
  JA_MEM_FIRST_LOGIN_YN: 'JAMemFirstLoginYN',
};

/**
 * LOCAL STORAGE NAME
 */
export const LOCAL_NAME = {
  kwd: 'SearchWord',
  tpview: 'TopicViewCount',
};

/**
 * SESSION STORAGE NAME
 */
export const SESSION_NAME = {
  tp: 'LinkedTopic',
  tprecom: 'TempTopicRecom',
  listTopicRecomClick: 'lctprecom',
};

/**
 * 2줄로 잘리는 글자 수
 */
export const LARGE_TEXTNUM = 125;
export const MEDIUM_TEXTNUM = 160;
export const SMALL_TEXTNUM = 85;

/**
 * 3줄로 잘리는 글자 수
 */
export const LARGE_TEXT = 140;
export const MEDIUM_TEXT = 185;
export const SMALL_TEXT = 85;

/**
 * 기사 본문 기본 폰트 크기
 */
export const DEFAULT_ARTICLE_FONTSIZE = 18;

/**
 * 검색 히스토리 최근 검색 최대 개수
 */
export const MAX_SAVE_SEARCH_WORD = 10;

/**
 * 이메일 주소 배열선언
 */
export const EMAIL_ADDR = [
  'naver.com',
  'daum.net',
  'nate.com',
  'gmail.com',
  'hanmail.net',
  'empas.com',
  'lycos.co.kr',
  'netsgo.com',
  'chol.com',
  'hotmail.com',
  'korea.com',
  'netian.com',
  'dreamwiz.com',
  'hanafos.com',
];

export const CALENDER_IMG =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15'%3E%3Cpath d='M4 0v1h3V0h1v1h3V0h1v1h2a1 1 0 011 1v12a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1h2V0h1zM3 2H1v12h13V2h-2v1h-1V2H8v1H7V2H4v1H3V2zm2 7.5v1H4v-1h1zm3 0v1H7v-1h1zm3 0v1h-1v-1h1zm-6-3v1H4v-1h1zm3 0v1H7v-1h1zm3 0v1h-1v-1h1z' fill='%231D1D1D' fill-rule='nonzero'/%3E%3C/svg%3E";

/**
 * 비로그인 사용자가 북마크 하기 위해 선택한 컨텐츠ID의 sessionStorage item key
 */
export const CHOICE_BOOKMARK = 'choiceBookmark';

export const LAZY_LOAD_DEFAULT_OPTION = {
  root: null,
  rootMargin: '800px',
  threshold: 0,
};

/**
 * 페이스북 키
 */
export const FB_APP_ID = '1011513095546498';
export const FB_APP_VERSION = 'v2.4';

/**
 * 앱 메뉴바 높이
 */
export const APP_MENU_HEIGHT = 50;

/**
 * 부동산 사이트 체크
 */
export const IS_REALESTATE = location.hostname === 'realestate.joongang.co.kr';

/**
 * 스티키 delay (민감하지 않게 처리)
 */
export const STICKY_DELAY = 10;

export const FAVO_CODE_NAME = {
  Q: '큐레이션',
  G: '시리즈',
  T: '토픽',
  L: '뉴스레터',
  R: '에디터',
};

export const FAVO_CODE_NAME_EN = {
  Q: 'curation',
  G: 'series',
  T: 'topic',
  L: 'newsletter',
  R: 'editor',
};

export const FAVO_CODE_LIST = Object.keys(FAVO_CODE_NAME);
export const FAV_DIALOG_DISPLAY_TARGET_KEY = ['Q', 'G', 'T', 'R'];
export const FAV_DIALOG_DISPLAY_TARGET_KEY_MIX = ['T', 'G', 'Q', 'R'];
